/* eslint-disable import/prefer-default-export */

import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm('Wykryto zmiany na stronie. Czy odświeżyć?');
  if (answer === true) {
    window.location.reload();
  }
};
