exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cennik-js": () => import("./../../../src/pages/cennik.js" /* webpackChunkName: "component---src-pages-cennik-js" */),
  "component---src-pages-dziekujemy-js": () => import("./../../../src/pages/dziekujemy.js" /* webpackChunkName: "component---src-pages-dziekujemy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-uslugi-js": () => import("./../../../src/pages/uslugi.js" /* webpackChunkName: "component---src-pages-uslugi-js" */),
  "component---src-pages-wazne-informacje-js": () => import("./../../../src/pages/wazne-informacje.js" /* webpackChunkName: "component---src-pages-wazne-informacje-js" */)
}

